import React, {useEffect, useState} from 'react';
import './App.css';
import LogFrame from "./assets/components/LogFrame/LogFrame";
import {Redirect, Route, Switch} from "react-router-dom";
import Aux from "./utils/Aux";
import Header from "./assets/components/LogFrame/Navigation/Header";
import Navigation from "./assets/components/LogFrame/Navigation/Navigation";
import ApiEndpoints from "./utils/ApiEndpoints";
import useHttp from "./hooks/http";
import {useSelector} from "react-redux";
import Footer from "./assets/components/LogFrame/Footer/Footer";
import Projects from "./assets/components/LogFrame/Projects/Projects";
import Table from "./assets/components/LogFrame/Table/Table";

function App() {
    const apiEndpoints = new ApiEndpoints();
    const {sendRequest, isLoading} = useHttp();

    const [projectId, setProjectId] = useState(null);

    const projects = useSelector(state => state.logframeReducer.projects);

    const changeProject = (id) => {
        setProjectId(id);
    };

    useEffect(() => {
        getProjects();
    }, []);

    const getProjects = () => {
        const {url, method, body, success, error} =
            apiEndpoints
                .getApiEndpoints()
                .projects
                .getProjects();
        sendRequest(url, method, body, success, error);
    };

    let routes = (
        <Aux>
            <Header/>
            <Navigation changeProject={changeProject} getProjects={getProjects}/>
            <Switch>
                <Route path={"/projects/:id"} component={Table}/>
                <Route path={"/projects"} component={Projects}/>
                <Redirect to={"/projects"}/>
            </Switch>
            <Footer/>
        </Aux>
    );
    return (
        <div className="App">
            <main>
                {routes}
            </main>
        </div>
    );
}

export default App;
