import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {projectItem as placeholderItem} from "../../../../utils/Data";
import {HIDE_MODAL, SHOW_PROJECT_MODAL} from "../../../../store/action-types/ui-actions";
import useSyncDispatch from "../../../../hooks/dispatch";
import ProjectModal from "../EditModal/ProjectModal";
import {ADD_GOAL, ADD_PROJECT} from "../../../../store/action-types/logFrame-actions";
import ApiEndpoints from "../../../../utils/ApiEndpoints";
import useHttp from "../../../../hooks/http";
import {Link, NavLink} from "react-router-dom";


const Navigation = ({changeProject, getProjects}) => {

    const apiEndpoints = new ApiEndpoints();
    const {sendRequest, isLoading} = useHttp();

    const {sendDispatch} = useSyncDispatch();
    const projects = useSelector(state => state.logframeReducer.projects);

    const [item, setItem] = useState(placeholderItem);

    const projectTags = projects?.map(item => {
            return <NavLink to={`/projects/${item.id}`} className="dropdown-item" key={item.id}>{item.name}</NavLink>
        }
    );

    const {showProjectModal, isEditing} = useSelector(state => {
        return {
            showProjectModal: state.uiReducer.showProjectModal,
            isEditing: state.uiReducer.isEditing
        }
    });

    const addProjectFn = () => {
        setItem(placeholderItem);
        sendDispatch(SHOW_PROJECT_MODAL, false);
    };

    const addProject = (project) => {
        const {url, method, body, success, error} =
            apiEndpoints
                .getApiEndpoints()
                .projects.addProject(project.title);

        sendRequest(url, method, body, success, error, response => {
            if (response) {
                getProjects();
                setItem(placeholderItem);
            }
        });
    };

    return (
        <div className="header collapse d-lg-flex p-0" id="headerMenuCollapse">
            <div className="px-5 w-100">
                <div className="row align-items-center">
                    <div className="m-auto pr-2">
                        <button className="btn btn-sm btn-outline-secondary" onClick={addProjectFn}>
                            <i className="fa fa-plus-circle mr-1"/>
                            Add Project
                        </button>
                    </div>
                    <div className="col-lg-3 ml-auto">
                        <form className="input-icon my-3 my-lg-0">
                            <input type="search" className="form-control header-search"
                                   placeholder="Search&hellip;" tabIndex="1"/>
                            <div className="input-icon-addon">
                                <i className="fe fe-search"/>
                            </div>
                        </form>
                    </div>
                    <div className="col-lg order-lg-first">
                        <ul className="nav nav-tabs border-0 flex-column flex-lg-row">
                            <li className="nav-item">
                                <NavLink to={'/'} className="nav-link">
                                    <i className="fe fe-home"/>
                                    Home
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <a href="" className="nav-link" data-toggle="dropdown">
                                    <i className="fe fe-list"/>
                                    Projects
                                </a>
                                <div className="dropdown-menu dropdown-menu-arrow">
                                    {projectTags}
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <ProjectModal title={isEditing ? "Edit Project" : 'Add Project'}
                              item={item}
                              isEditing={isEditing}
                              add={addProject}
                    // edit={editGoal}
                              show={showProjectModal}
                              clickBackdrop={() => {
                                  sendDispatch(HIDE_MODAL);
                                  setItem(placeholderItem)
                              }}/>
            </div>
        </div>
    );
};
export default Navigation;
