import axios from 'axios';

const instance = axios.create({
    baseURL: 'https://api.lf.lynq.io',
    headers: {
        'Access-Control-Allow-Origin': '*'
    }
});

instance.interceptors.request.use(
    config => {
        return config;
    },
    error => Promise.reject(error)
);

instance.interceptors.response.use(
    response => response,

    error => error
);

export default instance;
