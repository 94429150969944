import React, {useState} from 'react';
import Outcome from "./Outcome";
import EditModal from "../EditModal/EditModal";
import {ADD_OUTCOME, EDIT_OUTCOME} from "../../../../store/action-types/logFrame-actions";
import useSyncDispatch from "../../../../hooks/dispatch";
import {outputItem as placeholderItem} from "../../../../utils/Data";
import Aux from "../../../../utils/Aux";
import ApiEndpoints from "../../../../utils/ApiEndpoints";
import useHttp from "../../../../hooks/http";


const Outcomes = ({outcomes, goalId, goalTitle}) => {
    const apiEndpoints = new ApiEndpoints();
    const {sendRequest, isLoading} = useHttp();

    const [editMode, setEditMode] = useState(false);
    const [item, setItem] = useState(placeholderItem);
    const [showModal, setShowModal] = useState(false);
    const {sendDispatch} = useSyncDispatch();
    const [outcomeId, setOutcomeId] = useState(null);

    const editOutcomeFn = (outcome) => {
        setItem(outcome);
        setEditMode(true);
        setShowModal(true);
    };

    const addOutcome = (outcome) => {
        sendDispatch(ADD_OUTCOME, outcome, {goalId});
        setItem(placeholderItem);
    };

    const editOutcome = (outcome) => {
        sendDispatch(EDIT_OUTCOME, outcome, {goalId});
        setItem(placeholderItem);
    };

    const addOutputFn = (outcomeId) => {
        setShowModal(true);
        setItem(placeholderItem);
        setOutcomeId(outcomeId);
    };

    const addOutput = (output) => {
        const {url, method, body, success, error} =
            apiEndpoints
                .getApiEndpoints()
                .outputs
                .addOutput(outcomeId, {...output, status: 'not started', activities: []});
        sendRequest(url, method, body, success, error, response => {
            if (response) {
                getProjects();
                setItem(placeholderItem);
            }
        });
    };

    const getProjects = () => {
        const {url, method, body, success, error} =
            apiEndpoints
                .getApiEndpoints()
                .projects
                .getProjects();
        sendRequest(url, method, body, success, error);
    };

    return (
        <Aux>
            {outcomes?.map(outcome => {
                return <Outcome outcome={outcome} edit={editOutcomeFn} key={outcome.id} goalTitle={goalTitle}
                                addOutput={addOutputFn}
                                goalId={goalId} title={outcome.title}/>
            })}
            <EditModal title={editMode ? "Edit Output" : 'Add Output'}
                       item={item}
                       show={showModal}
                       add={addOutput}
                       // edit={editOutcomeFn}
                       editOutCome={editOutcomeFn}
                       isEditing={editMode}
                       clickBackdrop={() => {
                           setShowModal(false);
                           setItem(placeholderItem)
                       }}/>
        </Aux>
    );
};
export default Outcomes;
