import React from 'react';

const RowDropDown = ({modal, edit, itemType}) => {

    const onEdit = (event) => {
        event.preventDefault();
        // edit();
    };
    return (
        <td className="text-right">
            <div className="item-action dropdown">
                <a href="#" data-toggle="dropdown" className="icon"
                   aria-expanded="false"><i className="fe fe-more-vertical"/></a>
                <div className="dropdown-menu dropdown-menu-right">
                    <a href="#" className="dropdown-item"><i
                        className="dropdown-icon fe fe-eye">/</i> View {itemType}</a>
                    <a href="#" className="dropdown-item"
                       onClick={onEdit}><i
                        className="dropdown-icon fe fe-edit-2"/>
                        Edit {itemType}</a>
                    <a href="#" className="dropdown-item"><i
                        className="dropdown-icon fe fe-trash"/> Delete {itemType}</a>
                    <div className="dropdown-divider"/>
                    <a href="#" className="dropdown-item"><i
                        className="dropdown-icon fe fe-archive"/> Archive</a>
                </div>
            </div>
        </td>
    );
};
export default RowDropDown;
