import React, {useEffect, useRef, useState} from 'react';
import ModalWrapper from "../Modals/ModalWrapper/ModalWrapper";
import './EditModal.css';


const ProjectModal = (props) => {
    const {item, isEditing, title, show, clickBackdrop, add, edit} = props;
    const [editMode, setEditMode] = useState(false);
    const [stateItem, setStateItem] = useState({
        ...item
    });
    let inputRef = useRef(null);


    useEffect(() => {
        setStateItem({
            ...item
        });
    }, [item, editMode]);

    const onSave = () => {
        isEditing ? edit(stateItem) : add(stateItem);
        clickBackdrop();
        setEditMode(false);
        /*  setStateItem({});
          setFiles([]);*/
    };

    const closeModal = () => {
        /*  setStateItem({});
          setFiles([]);*/
        setEditMode(false);
        clickBackdrop();
    };


    const onChangeValue = (event) => {
        const {value, name} = event.target;
        setStateItem(prevState => {
            return {
                ...prevState,
                [name]: value
            }
        })
    };


    return (
        <ModalWrapper show={show} clickBackdrop={closeModal}>
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{title}</h5>
                        <button type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={closeModal}/>
                    </div>

                    <div className="modal-body custom-scroll">
                        <div className="form-fieldset">
                            <div className="form-group">
                                <label className="form-label">Title<span
                                    className="form-required">*</span></label>
                                <textarea className="form-control"
                                          onChange={onChangeValue}
                                          name="title"
                                          disabled={!editMode && isEditing}
                                          value={stateItem.title}/>
                            </div>

                        </div>


                    </div>
                    <hr className="m-0"/>
                    <div className="d-flex justify-content-between p-3">
                        {isEditing ? <span className="d-flex">
                                {!editMode ? <button className="btn btn-warning" onClick={() => {
                                        setEditMode(true)
                                    }}><i className="fa fa-pen-alt mr-1"/>Edit
                                    </button> :
                                    <button className="btn btn-outline-danger" onClick={() => {
                                        setEditMode(false)
                                    }}><i className="fa fa-times"> Cancel</i>
                                    </button>}
                                </span> : <span/>}
                        <div className="d-flex justify-content-between">
                            <button type="button" className="btn btn-danger mr-2" onClick={clickBackdrop}>Close
                            </button>
                            <button type="button" className="btn btn-primary"
                                    onClick={onSave}>Save changes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </ModalWrapper>
    );
};
export default ProjectModal;
