import React from 'react';

const Footer = (props) => {
    return (<footer className="footer fixed-bottom">
            <div className="container">
                <div className="row align-items-center flex-row-reverse">
                    <div className="col-auto ml-lg-auto">
                        <div className="row align-items-center">
                            <div className="col-auto">
                                <ul className="list-inline list-inline-dots mb-0">
                                    <li className="list-inline-item"><a href="./docs/index.html">Documentation</a>
                                    </li>
                                    <li className="list-inline-item"><a href="./faq.html">FAQ</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-auto mt-3 mt-lg-0 text-center">
                        Copyright © 2020 <a href=".">lynq.io</a>. Theme by <a href="https://codecalm.net"
                                                                              target="_blank">codecalm.net</a> All
                        rights reserved.
                    </div>
                </div>
            </div>
        </footer>
    );
};
export default Footer;
