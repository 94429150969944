import {
    ADD_ACTIVITY,
    ADD_GOAL,
    ADD_OUTCOME,
    ADD_OUTPUT,
    ADD_PROJECT,
    EDIT_ACTIVITY,
    EDIT_GOAL,
    EDIT_OUTCOME,
    EDIT_OUTPUT,
    EDIT_PROJECT,
    GET_PROJECTS_ERROR,
    GET_PROJECTS_SUCCESS,
    REMOVE_ACTIVITY,
    REMOVE_GOAL,
    REMOVE_OUTCOME,
    REMOVE_OUTPUT,
    REMOVE_PROJECT
} from "../action-types/logFrame-actions";

const initialState = {
    projects: [],
};

const logframeReducer = (state = initialState, action) => {

    switch (action.type) {
        case ADD_PROJECT:
            return addProject(state, action);
        case EDIT_PROJECT:
            return editProject(state, action);
        case REMOVE_PROJECT:
            return removeProject(state, action);
        case ADD_GOAL:
            return addGoal(state, action);
        case EDIT_GOAL:
            return editGoal(state, action);
        case REMOVE_GOAL:
            return removeGoal(state, action);
        case ADD_OUTCOME:
            return addOutcome(state, action);
        case EDIT_OUTCOME:
            return editOutcome(state, action);
        case REMOVE_OUTCOME:
            return removeOutcome(state, action);
        case ADD_OUTPUT:
            return addOutput(state, action);
        case EDIT_OUTPUT:
            return editOutput(state, action);
        case REMOVE_OUTPUT:
            return removeOutput(state, action);
        case ADD_ACTIVITY:
            return addActivity(state, action);
        case EDIT_ACTIVITY:
            return editActivity(state, action);
        case REMOVE_ACTIVITY:
            return removeActivity(state, action);
        case GET_PROJECTS_SUCCESS:
            return {
                ...state,
                projects: action.payload
            };
        case GET_PROJECTS_ERROR:
            return {
                ...state,
                projects: []
            };
        default:
            return state;
    }
};


const addProject = (state, action) => {
    const project = action.payload.project;
    return {
        ...state,
        projects: [...state.projects, project],
    }
};

const editProject = (state, action) => {
    return state;
};

const removeProject = (state, action) => {
    return state;
};

const addGoal = (state, action) => {
    const goal = action.payload.goal;
    const projectId = action.payload.projectId;
    let newState = {
        ...state,
        projects: [...state.projects]
    };
    let projectIndex = newState.projects.findIndex(project => project.id === projectId);
    let project = newState.projects[projectIndex];
    newState.projects.splice(projectIndex, 1);
    project.goals.push(goal);
    newState.projects.splice(projectIndex, 0, project);
    return newState;
};

const editGoal = (state, action) => {
    let newState = {
        ...state,
        goals: [...state.goals]
    };
    let index = newState.goals.findIndex(goal => goal.title === action.payload.title);
    newState.goals.splice(index, 1);
    newState.goals.splice(index, 0, action.payload);
    return newState;
};

const removeGoal = (state, action) => {

};

// outcomes
const addOutcome = (state, action) => {
    const goalId = action.payload.goalId;
    const projectId = action.payload.projectId;
    const outcome = action.payload.outcome;
    let newState = {
        ...state,
        projects: [...state.projects]
    };
    let projectIndex = newState.projects.findIndex(project => project.id === projectId);
    let project = {
        ...newState.projects[projectIndex],
        goals: [...newState.projects[projectIndex].goals]
    };
    newState.projects.splice(projectIndex, 1);
    let goalIndex = project.goals.findIndex(goal => goal.id === goalId);
    project.goals[goalIndex].outcomes.push(outcome);
    newState.projects.splice(projectIndex, 0, project);
    return newState;
};

const editOutcome = (state, action) => {

};

const removeOutcome = (state, action) => {

};

//outputs

const addOutput = (state, action) => {

};

const editOutput = (state, action) => {

};

const removeOutput = (state, action) => {

};

// activities

const addActivity = (state, action) => {

};

const editActivity = (state, action) => {

};

const removeActivity = (state, action) => {

};


export default logframeReducer;
