import React, {useEffect, useState} from 'react';
import RowDropDown from "../Table/RowDropDown";
import Outcomes from "../Outcomes/Outcomes";
import Aux from "../../../../utils/Aux";
import usePrevProps from "../../../../hooks/prevProps";

const Goal = ({goal, edit, addOutcome, title}) => {
    const [showOutcomes, setShowOutcomes] = useState(false);

    const editGoal = () => {
        edit(goal);
    };

    const addOutcomeFn = () => {
        addOutcome(goal.id);
    };
    const prevProps = usePrevProps({goal: {...goal}});
    useEffect(() => {
        if (prevProps?.goal?.outcomes.length === goal?.outcomes.length && prevProps) {
            setShowOutcomes(true);
        }
    }, [goal.outcomes?.length]);

    return (
        <Aux>
            <tr className="mb-4 bg-lightblue">
                <th colSpan="7" className="border-lightblue">
                    <span>
                        <h4 className="mt-3 d-inline-block mr-3 bg-white py-1 px-3 border-radius color-lightblue">Goal: {title}</h4>
                    </span>
                </th>
                <th colSpan="1">
                    <button className="btn btn-sm btn-light dark-silver-bg color-lightyellow" onClick={addOutcomeFn}>
                        <i className="fa fa-plus-circle mr-1"/>
                        Add Outcome
                    </button>
                </th>
            </tr>
            <tr className="border-lightblue">
                <td>
                    <button className="btn btn-sm btn-outline-secondary btn-white border-black" onClick={() => {
                        setShowOutcomes(prevState => {
                            return !prevState
                        })
                    }}>
                        <i className={!showOutcomes ? 'fa fa-caret-down mr-1' : 'fa fa-caret-up mr-1'}/>
                        Outcomes
                    </button>
                </td>
                <td>{goal.summary}
                    <div className="row mt-1">
                        <div className="col-12">
                            <div className="clearfix">
                                <div className="float-left">
                                    <strong>42%</strong>
                                </div>
                                <div className="float-right">
                                    <small className="text-muted">Jun 11, 2020 - Sep 10,
                                        2020</small>
                                </div>
                            </div>
                            <div className="progress progress-xs">
                                <div className="progress-bar bg-yellow"
                                     role="progressbar"
                                     aria-valuenow="42"
                                     aria-valuemin="0"
                                     aria-valuemax="100"/>
                            </div>
                        </div>
                    </div>
                </td>
                <td>{goal.indicators}</td>
                <td>{goal.verification}</td>
                <td>{goal.risks_assumptions}</td>
                <td>
                    {goal.related_links?.map(link => {
                        return <span key={link}><a href={link}>{link}</a><br/></span>
                    })}
                </td>
                <td>
                    {goal.comments?.map(comment => {
                        return <span key={comment}>{comment}<br/></span>
                    })}
                </td>
                <RowDropDown edit={editGoal} itemType="Goal"/>
            </tr>
            {showOutcomes && <Outcomes outcomes={goal.outcomes} goalId={goal.id} goalTitle={goal.title}/>}
        </Aux>
    );
};
export default Goal;
