import React, {useEffect, useState} from 'react';
import RowDropDown from "../Table/RowDropDown";
import Outputs from "../Outputs/Outputs";
import Aux from "../../../../utils/Aux";
import usePrevProps from "../../../../hooks/prevProps";

const Outcome = ({outcome, edit, goalId, title, goalTitle, addOutput}) => {
    const [showOutputs, setShowOutputs] = useState(false);

    const prevProps = usePrevProps({outcome});

    const editOutcome = () => {
        edit(outcome);
    };

    const addOutputFn = () => {
        addOutput(outcome.id);
    };

    useEffect(() => {
        if (prevProps?.outcome.outputs?.length !== outcome.outputs?.length && prevProps) {
            setShowOutputs(true);
        }
    }, [outcome.outputs?.length]);

    return (
        <Aux>
            <tr className="border-lightyellow bg-lightyellow">
                <th colSpan="7">
                    <span
                        className="mt-3 d-inline-block mr-4 font-weight-bolder background-grey px-3 border-radius color-lightyellow"> {goalTitle} / {outcome.title}</span>
                </th>
                <td colSpan="1" className="text-right">
                    <button className="btn btn-sm btn-light color-lightgreen"
                            onClick={addOutputFn}>
                        <i className="fa fa-plus-circle mr-1"/>
                        Add Output
                    </button>
                </td>
            </tr>
            <tr className="border-lightyellow">
                <td>
                    <button className="btn btn-sm btn-outline-secondary btn-white border-black" onClick={() => {
                        setShowOutputs(prevState => {
                            return !prevState
                        })
                    }}>
                        <i className={!showOutputs ? 'fa fa-caret-down mr-1' : 'fa fa-caret-up mr-1'}/>
                        Outputs
                    </button>
                </td>
                <td>{outcome.summary}</td>
                <td>{outcome.indicators}</td>
                <td>{outcome.verification}</td>
                <td>{outcome.risks_assumptions}</td>
                <td>
                    {outcome?.related_links?.map(link => {
                        return <Aux key={link}><a href={link}>{link}</a><br/></Aux>
                    })}
                </td>
                <td>
                    {outcome?.comments?.map(comment => {
                        return <span key={comment}>{comment}<br/></span>
                    })}
                </td>
                <RowDropDown edit={editOutcome} itemType="Outcome"/>
            </tr>
            {showOutputs &&
            <Outputs outputs={outcome.outputs} goalId={goalId} goalTitle={goalTitle} outcomeId={outcome.id}
                     outcomeTitle={outcome.title}/>}
        </Aux>
    );
};
export default Outcome;
