import React from 'react';
import Activity from "./Activity";
import Aux from "../../../../utils/Aux";

const Activities = ({activities, output, goalTitle, outcomeTitle}) => {
    return (
        <Aux>
            {activities && activities.map((activity, index) => {
                return <Activity key={activity.id} activity={activity} show={index === 0} goalTitle={goalTitle}
                                 outcomeTitle={outcomeTitle} outputTitle={output.title}/>
            })}
        </Aux>
    );
};
export default Activities;
