import {ADD_OUTCOME, ERROR, GET_PROJECTS_ERROR, GET_PROJECTS_SUCCESS} from "../store/action-types/logFrame-actions";

let instance = null;

class ApiEndpoints {
    constructor() {
        if (!instance) {
            instance = this;
        }
        return instance;
    }

    getApiEndpoints() {
        let endpoints = {};
        endpoints.projects = {
            getProjects: () => {
                return {
                    url: `/test/get-projects`,
                    method: 'GET',
                    body: null,
                    success: GET_PROJECTS_SUCCESS,
                    error: GET_PROJECTS_ERROR
                }
            },
            addProject: (name) => {
                return {
                    url: '/test/add-project',
                    method: 'POST',
                    body: name,
                    success: null,
                    error: null
                }
            }
        };
        endpoints.goals = {
            addGoal: (projectId, goal) => {
                return {
                    url: `/test/add-goal/${projectId}`,
                    method: 'POST',
                    body: goal,
                    success: null,
                    error: null
                }
            }
        };
        endpoints.outcomes = {
            addOutcome: (goalId, outcome) => {
                return {
                    url: `/test/add-outcome/${goalId}`,
                    method: 'POST',
                    body: outcome,
                    success: null,
                    error: null
                }
            }
        };
        endpoints.outputs = {
            addOutput: (outcomeId, output) => {
                return {
                    url: `/test/add-output/${outcomeId}`,
                    method: 'POST',
                    body: output,
                    success: null,
                    error: null
                }
            },
            changeStatus: (outputId, status) => {
                return {
                    url: `/test/update-output-status/${outputId}?newStatus=${status}`,
                    method: 'PUT',
                    body: status,
                    success: null,
                    error: null
                }
            }
        };
        endpoints.activity = {
            addActivity: (outputId, activity) => {
                return {
                    url: `/test/add-activity/${outputId}`,
                    method: 'POST',
                    body: activity,
                    success: null,
                    error: null
                }
            }
        };
        return endpoints;
    }
}

export default ApiEndpoints;
