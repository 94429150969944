import React from 'react';
import {withRouter} from "react-router-dom";
import {useSelector} from "react-redux";
import history from "../../../../utils/history";

const Projects = (props) => {
    const projects = useSelector(state => state.logframeReducer.projects);

    const onSelectProject = (id) => {
        history.push(`/projects/${id}`);
    };

    return (
        <div className="page">
            <div className="page-main mb-5">
                <div className={'d-flex flex-wrap p-5'}>
                    {projects?.map(project => (
                        <div className='project-box' key={project.id}
                             onClick={() => onSelectProject(project.id)}>{project.name}</div>))}
                </div>
            </div>
        </div>
    );
};

export default withRouter(Projects);
