import {HIDE_MODAL, SHOW_MODAL, SHOW_PROJECT_MODAL} from "../action-types/ui-actions";

const initialState = {
    showEditModal: false,
    showProjectModal : false,
    isEditing: false,
    type: null
};

const uiReducer = (state = initialState, action) => {

    switch (action.type) {
        case SHOW_MODAL:
            return {
                ...state,
                showEditModal: true,
                showProjectModal: false,
                isEditing: action.payload.isEditing,
                type: action.payload.type
            };
        case HIDE_MODAL:
            return {
                ...state,
                showEditModal: false,
                showProjectModal: false,
                isEditing: false,
                type: null
            };
        case SHOW_PROJECT_MODAL:
            return {
                ...state,
                showEditModal: false,
                showProjectModal: true,
                isEditing: action.payload
            }

        default:
            return state;
    }
};

export default uiReducer;
