import {useDispatch} from "react-redux";
import {useCallback, useReducer} from "react";
import axios from '../custom-axios';


const SENDING = 'SENDING';
const DONE = 'DONE';
const ERROR = 'ERROR';
const CLEAR = 'CLEAR';

const initialState = {
    isLoading: false,
    error: null
};

const httpReducer = (state = initialState, action) => {
    switch (action.type) {
        case SENDING:
            return {
                ...state,
                isLoading: true
            };
        case DONE:
            return {
                ...state,
                isLoading: false
            };
        case ERROR:
            return {
                isLoading: false,
                error: action.payload
            };
        case CLEAR:
            return initialState;
        default:
            throw new Error("This should never occur!");
    }
};

const useHttp = () => {
    const [httpState, changeHttpState] = useReducer(httpReducer, initialState);
    const dispatch = useDispatch();

    const clear = useCallback(() => {
        changeHttpState({type: CLEAR});
    }, []);

    const sendRequest = useCallback(
        (url, method, body, actionTypeSuccess, actionTypeError, successCallback) => {
            changeHttpState({type: SENDING});
            axios({
                url: url,
                method: method,
                data: body,
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => {
                dispatch({
                    type: actionTypeSuccess,
                    payload: response.data
                });
                changeHttpState({type: DONE});
                successCallback && successCallback(response.data);
            }).catch(error => {
                dispatch({
                    type: actionTypeError,
                    payload: error
                });
                changeHttpState({type: ERROR, payload: error});
            });
        },
        []
    );

    return {
        sendRequest: sendRequest,
        isLoading: httpState.isLoading,
        error: httpState.error,
        clearError: clear
    }
};

export default useHttp;
