import React, {useState} from 'react';
import Output from "./Output";
import Aux from "../../../../utils/Aux";
import {activityItem as placeholderItem} from "../../../../utils/Data";
import EditModal from "../EditModal/EditModal";
import ApiEndpoints from "../../../../utils/ApiEndpoints";
import useHttp from "../../../../hooks/http";

const Outputs = ({outputs, goalId, outcomeId, outcomeTitle, goalTitle}) => {
    const apiEndpoints = new ApiEndpoints();
    const {sendRequest, isLoading} = useHttp();

    const [editMode, setEditMode] = useState(false);
    const [item, setItem] = useState(placeholderItem);
    const [showModal, setShowModal] = useState(false);
    const [outputId, setOutputId] = useState(null);

    const addActivityFn = (outputId) => {
        setShowModal(true);
        setItem(placeholderItem);
        setOutputId(outputId);
    };

    const addActivity = (activity) => {
        const {url, method, body, success, error} =
            apiEndpoints
                .getApiEndpoints()
                .activity
                .addActivity(outputId, activity);
        sendRequest(url, method, body, success, error, response => {
            if (response) {
                getProjects();
                setItem(placeholderItem);
            }
        });
    };

    const getProjects = () => {
        const {url, method, body, success, error} =
            apiEndpoints
                .getApiEndpoints()
                .projects
                .getProjects();
        sendRequest(url, method, body, success, error);
    };

    return (
        <Aux>
            {outputs?.map((output) => {
                return <Output output={output} key={output.title} goalId={goalId} outcomeId={outcomeId}
                               outcomeTitle={outcomeTitle} goalTitle={goalTitle} addActivity={addActivityFn}/>
            })}

            <EditModal title={editMode ? "Edit Activity" : 'Add Activity'}
                       item={item}
                       show={showModal}
                       add={addActivity}
                       isEditing={editMode}
                       clickBackdrop={() => {
                           setShowModal(false);
                           setItem(placeholderItem)
                       }}/>
        </Aux>
    );
};
export default Outputs;
