import {combineReducers} from "redux";
import logframeReducer from "./logframeReducer";
import uiReducer from "./uiReducer";

const rootReducer = combineReducers({
    logframeReducer,
    uiReducer
});

export default rootReducer;
