export const goals = [
    {
        title: 'Goal 1',
        summary: '10% increase in the number of Grades 5-6 primary students continuing on to high school within 3 years.',
        indicators: 'Percentage of Grades 5-6 primary students continuing on to high school.',
        verification: 'Comparison of primary and high school enrollment records.',
        risks_assumptions: 'N/A',
        related_links: ['https://lf.lynq.io/',
            'https://lf.lynq.io/1',
            'https://lf.lynq.io/2',
            'https://lf.lynq.io/3',
            'https://lf.lynq.io/4',
            'https://lf.lynq.io/5',
            'https://lf.lynq.io/6',
            'https://lf.lynq.io/7',
            'https://lf.lynq.io/8',
            'https://lf.lynq.io/9',
            'https://lf.lynq.io/10',
            'https://lf.lynq.io/11',
            'https://lf.lynq.io/12',
            'https://lf.lynq.io/13',
            'https://lf.lynq.io/14'],
        outcomes: [
            {
                title: 'Outcome 1',
                summary: 'Improve reading proficiency among children in Grades 5-6 by 20% within 3 years.',
                indicators: 'Reading proficiency among children in Grades 5-6.',
                verification: 'Six monthly reading proficiency tests using the national assessment tool.',
                risks_assumptions: 'Improved reading proficiency provides self confidence required to stay in school.',
                related_links: ['https://lf.lynq.io/'],
                outputs: [
                    {
                        title: 'Output 1',
                        summary: '500 Grades 5-6 students with low reading proficiency complete a reading summer camp.',
                        indicators: 'Number of students completing a reading summer camp.',
                        verification: 'Summer camp attendance records.',
                        risks_assumptions: 'Children apply what they learnt at the summer camp.',
                        related_links: ['https://lf.lynq.io/'],
                        activities: [
                            {
                                title: 'Activity 1',
                                summary: 'Run five reading summer camps, each with 100 Grades 5-6 students who have low reading proficiency.',
                                indicators: 'Number of summer camps run.',
                                verification: 'Summer camp records.',
                                risks_assumptions: 'Parents of children with low reading proficiency are interested in the attending the camps.',
                                related_links: ['https://lf.lynq.io/']
                            },
                            {
                                title: 'Activity 2',
                                summary: 'Distribute 500 "Reading at Home" kits to parents of children attending summer camps.',
                                indicators: 'Number of kits distributed.',
                                verification: 'Kit distribution records.',
                                risks_assumptions: 'Parents are interested and able to use the kits at home.',
                                related_links: ['https://lf.lynq.io/']
                            }
                        ]
                    },
                    {
                        title: 'Output 2',
                        summary: '500 parents of children in Grades 5-6 with low reading proficiency help their children read at home.',
                        indicators: 'Number of parents helping their children read at home.',
                        verification: 'Survey of parents conducted at the end of each summer camp.',
                        risks_assumptions: 'Children are interested in reading with their parents.',
                        related_links: ['https://lf.lynq.io/']
                    }
                ]
            },
            {
                title: 'Outcome 2',
                summary: 'Improve reading proficiency among children in Grades 5-6 by 20% within 3 years.',
                indicators: 'Reading proficiency among children in Grades 5-6.',
                verification: 'Six monthly reading proficiency tests using the national assessment tool.',
                risks_assumptions: 'Improved reading proficiency provides self confidence required to stay in school.',
                related_links: ['https://lf.lynq.io/'],
                outputs: [
                    {
                        title: 'Output 2',
                        summary: '500 Grades 5-6 students with low reading proficiency complete a reading summer camp.',
                        indicators: 'Number of students completing a reading summer camp.',
                        verification: 'Summer camp attendance records.',
                        risks_assumptions: 'Children apply what they learnt at the summer camp.',
                        related_links: ['https://lf.lynq.io/'],
                        activities: [
                            {
                                title: 'Activity 5',
                                summary: 'Run five reading summer camps, each with 100 Grades 5-6 students who have low reading proficiency.',
                                indicators: 'Number of summer camps run.',
                                verification: 'Summer camp records.',
                                risks_assumptions: 'Parents of children with low reading proficiency are interested in the attending the camps.',
                                related_links: ['https://lf.lynq.io/']
                            },
                            {
                                title: 'Activity 2',
                                summary: 'Distribute 500 "Reading at Home" kits to parents of children attending summer camps.',
                                indicators: 'Number of kits distributed.',
                                verification: 'Kit distribution records.',
                                risks_assumptions: 'Parents are interested and able to use the kits at home.',
                                related_links: ['https://lf.lynq.io/']
                            }
                        ]
                    },
                    {
                        title: 'Output 2',
                        summary: '500 parents of children in Grades 5-6 with low reading proficiency help their children read at home.',
                        indicators: 'Number of parents helping their children read at home.',
                        verification: 'Survey of parents conducted at the end of each summer camp.',
                        risks_assumptions: 'Children are interested in reading with their parents.',
                        related_links: ['https://lf.lynq.io/']
                    }
                ]
            }
        ]
    },


    {
        title: 'Goal 2',
        summary: '10% increase in the number of Grades 5-6 primary students continuing on to high school within 3 years.',
        indicators: 'Percentage of Grades 5-6 primary students continuing on to high school.',
        verification: 'Comparison of primary and high school enrollment records.',
        risks_assumptions: 'N/A',
        related_links: ['https://lf.lynq.io/',
            'https://lf.lynq.io/1',
            'https://lf.lynq.io/2',
            'https://lf.lynq.io/3',
            'https://lf.lynq.io/4',
            'https://lf.lynq.io/5',
            'https://lf.lynq.io/6',
            'https://lf.lynq.io/7',
            'https://lf.lynq.io/8',
            'https://lf.lynq.io/9',
            'https://lf.lynq.io/10',
            'https://lf.lynq.io/11',
            'https://lf.lynq.io/12',
            'https://lf.lynq.io/13',
            'https://lf.lynq.io/14'],
        outcomes: [
            {
                title: 'Outcome 1',
                summary: 'Improve reading proficiency among children in Grades 5-6 by 20% within 3 years.',
                indicators: 'Reading proficiency among children in Grades 5-6.',
                verification: 'Six monthly reading proficiency tests using the national assessment tool.',
                risks_assumptions: 'Improved reading proficiency provides self confidence required to stay in school.',
                related_links: ['https://lf.lynq.io/'],
                outputs: [
                    {
                        title: 'Output 1',
                        summary: '500 Grades 5-6 students with low reading proficiency complete a reading summer camp.',
                        indicators: 'Number of students completing a reading summer camp.',
                        verification: 'Summer camp attendance records.',
                        risks_assumptions: 'Children apply what they learnt at the summer camp.',
                        related_links: ['https://lf.lynq.io/'],
                        activities: [
                            {
                                title: 'Activity 1',
                                summary: 'Run five reading summer camps, each with 100 Grades 5-6 students who have low reading proficiency.',
                                indicators: 'Number of summer camps run.',
                                verification: 'Summer camp records.',
                                risks_assumptions: 'Parents of children with low reading proficiency are interested in the attending the camps.',
                                related_links: ['https://lf.lynq.io/']
                            },
                            {
                                title: 'Activity 2',
                                summary: 'Distribute 500 "Reading at Home" kits to parents of children attending summer camps.',
                                indicators: 'Number of kits distributed.',
                                verification: 'Kit distribution records.',
                                risks_assumptions: 'Parents are interested and able to use the kits at home.',
                                related_links: ['https://lf.lynq.io/']
                            }
                        ]
                    },
                    {
                        title: 'Output 2',
                        summary: '500 parents of children in Grades 5-6 with low reading proficiency help their children read at home.',
                        indicators: 'Number of parents helping their children read at home.',
                        verification: 'Survey of parents conducted at the end of each summer camp.',
                        risks_assumptions: 'Children are interested in reading with their parents.',
                        related_links: ['https://lf.lynq.io/']
                    }
                ]
            },
            {
                title: 'Outcome 1',
                summary: 'Improve reading proficiency among children in Grades 5-6 by 20% within 3 years.',
                indicators: 'Reading proficiency among children in Grades 5-6.',
                verification: 'Six monthly reading proficiency tests using the national assessment tool.',
                risks_assumptions: 'Improved reading proficiency provides self confidence required to stay in school.',
                related_links: ['https://lf.lynq.io/'],
                outputs: [
                    {
                        title: 'Output 2',
                        summary: '500 Grades 5-6 students with low reading proficiency complete a reading summer camp.',
                        indicators: 'Number of students completing a reading summer camp.',
                        verification: 'Summer camp attendance records.',
                        risks_assumptions: 'Children apply what they learnt at the summer camp.',
                        related_links: ['https://lf.lynq.io/'],
                        activities: [
                            {
                                title: 'Activity 1',
                                summary: 'Run five reading summer camps, each with 100 Grades 5-6 students who have low reading proficiency.',
                                indicators: 'Number of summer camps run.',
                                verification: 'Summer camp records.',
                                risks_assumptions: 'Parents of children with low reading proficiency are interested in the attending the camps.',
                                related_links: ['https://lf.lynq.io/']
                            },
                            {
                                title: 'Activity 2',
                                summary: 'Distribute 500 "Reading at Home" kits to parents of children attending summer camps.',
                                indicators: 'Number of kits distributed.',
                                verification: 'Kit distribution records.',
                                risks_assumptions: 'Parents are interested and able to use the kits at home.',
                                related_links: ['https://lf.lynq.io/']
                            }
                        ]
                    },
                    {
                        title: 'Output 2',
                        summary: '500 parents of children in Grades 5-6 with low reading proficiency help their children read at home.',
                        indicators: 'Number of parents helping their children read at home.',
                        verification: 'Survey of parents conducted at the end of each summer camp.',
                        risks_assumptions: 'Children are interested in reading with their parents.',
                        related_links: ['https://lf.lynq.io/']
                    }
                ]
            }
        ]
    }
];


export const projects = [
    {
        id: 1,
        title: 'Project 1',
        goals: JSON.parse(JSON.stringify(goals))
    },
    {
        id: 2,
        title: 'Project 2',
        goals: []
    },
    {
        id: 3,
        title: 'Project 3',
        goals: JSON.parse(JSON.stringify(goals))
    }
];

export const projectItem = {
    id: '',
    title: '',
    goals: []
};

export const goalItem = {
    summary: '',
    indicators: '',
    verification: '',
    risks_assumptions: '',
    related_links: [],
    outcomes: [],
    comments: [],
    title: ''
};

export const outcomeItem = {
    summary: '',
    indicators: '',
    verification: '',
    risks_assumptions: '',
    related_links: [],
    outputs: [],
    comments: [],
    title: ''
};

export const outputItem = {
    summary: '',
    indicators: '',
    verification: '',
    risks_assumptions: '',
    related_links: [],
    activities: [],
    comments: [],
    title: ''
};

export const activityItem = {
    summary: '',
    indicators: '',
    verification: '',
    risks_assumptions: '',
    related_links: [],
    comments: [],
    title: ''
};
