import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {applyMiddleware, compose} from "redux";
import {createStore} from "redux";
import {Provider} from "react-redux";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import rootReducer from "./store/reducers/rootReducer";
import thunk from "redux-thunk";
import history from "./utils/history";


import './assets/css/dashboard.css';
import './assets/css/tabler.css';
import '../node_modules/bootstrap/dist/js/bootstrap.min';
import './assets/fonts/fontawesome/css/fontawesome-all.min.css';
import {Route, Router} from "react-router-dom";

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
));


const app = (
    <Provider store={store}>
        <Router history={history} >
            <Route path="/" component={App}/>
        </Router>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
