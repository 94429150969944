import React, {useEffect, useRef, useState} from 'react';
import ModalWrapper from "../Modals/ModalWrapper/ModalWrapper";
import './EditModal.css';
import {goalItem as placeholderItem} from "../../../../utils/Data";


const DETAILS = 'details';
const FILES = 'files';

const  EditModal = (props) => {
    const {item, isEditing, title, show, clickBackdrop, add, edit} = props;
    const [editMode, setEditMode] = useState(false);
    const [activeTab, setActiveTab] = useState(DETAILS);
    const [stateItem, setStateItem] = useState({
        ...item
    });
    const [files, setFiles] = useState([]);
    let inputRef = useRef(null);

    const [link, setLink] = useState('');
    const [comment, setComment] = useState('');

    const onSave = () => {
        isEditing ? edit(stateItem) : add(stateItem);
        clickBackdrop();
        setEditMode(false);
        setStateItem(placeholderItem);
        setFiles([]);
    };

    const closeModal = () => {
        setStateItem(placeholderItem);
        setFiles([]);
        setEditMode(false);
        clickBackdrop();
    };


    const onChangeValue = (event) => {
        const {value, name} = event.target;
        setStateItem(prevState => {
            return {
                ...prevState,
                [name]: value
            }
        })
    };

    const onAddLink = (event) => {
        const newItem = {
            ...stateItem,
            related_links: [...stateItem.related_links]
        };
        if (link.length > 0) {
            newItem.related_links.push(link);
            setStateItem(newItem);
            setLink('');
        }
    };

    const onAddComment = (event) => {
        const newItem = {
            ...stateItem,
            comments: [...stateItem.comments]
        };
        if (comment.length > 0) {
            newItem.comments.push(comment);
            setStateItem(newItem);
            setComment('');
        }
    };

    const removeLink = (item) => {
        const newItem = {
            ...stateItem,
            related_links: [...stateItem.related_links]
        };
        newItem.related_links = newItem.related_links.filter(link => link !== item);
        setStateItem(newItem);
    };

    const removeComment = (item) => {
        const newItem = {
            ...stateItem,
            comments: [...stateItem.comments]
        };
        newItem.comments = newItem.comment.filter(c => c !== item);
        setStateItem(newItem);
    };

    const addFile = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFiles([...files, file]);
        }
    };

    const removeFile = (event, file) => {
        event.preventDefault();
        let newFiles = [...files].filter(f => f !== file);
        setFiles(newFiles);
    };

    return (
        <ModalWrapper show={show} clickBackdrop={closeModal}>
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{title}</h5>
                        <button type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={closeModal}/>
                    </div>
                    <div className="d-flex justify-content-center text-uppercase mt-1">
                            <span className={`custom-nav-item mr-5 ${activeTab === DETAILS && 'active-link'}`}
                                  onClick={() => {
                                      setActiveTab(DETAILS)
                                  }}>Details</span>
                        <span className={`custom-nav-item mr-5 ${activeTab === FILES && 'active-link'}`}
                              onClick={() => {
                                  setActiveTab(FILES)
                              }}>Files</span>
                    </div>
                    <div className="modal-body custom-scroll">
                        {activeTab === DETAILS && <div className="form-fieldset">
                            <div className="form-group">
                                <label className="form-label">Title<span
                                    className="form-required">*</span></label>
                                <input className="form-control"
                                          onChange={onChangeValue}
                                          name="title"
                                          disabled={!editMode && isEditing}
                                          value={stateItem.title}/>
                            </div>
                            <div className="form-group">
                                <label className="form-label">Summary<span
                                    className="form-required">*</span></label>
                                <textarea className="form-control"
                                          onChange={onChangeValue}
                                          name="summary"
                                          disabled={!editMode && isEditing}
                                          value={stateItem.summary}/>
                            </div>
                            <div className=" form-group">
                                <label className=" form-label">Indicators<span
                                    className=" form-required">*</span></label>
                                <textarea className=" form-control"
                                          onChange={onChangeValue}
                                          name="indicators"
                                          disabled={!editMode && isEditing}
                                          value={stateItem.indicators}/>
                            </div>
                            <div className=" form-group">
                                <label className=" form-label">Means of Verification<span
                                    className=" form-required">*</span></label>
                                <textarea className=" form-control"
                                          onChange={onChangeValue}
                                          name="verification"
                                          disabled={!editMode && isEditing}
                                          value={stateItem.verification}/>
                            </div>
                            <div className=" form-group mb-0">
                                <label className=" form-label">Risks / Assumptions</label>
                                <textarea className=" form-control"
                                          onChange={onChangeValue}
                                          name="risks_assumptions"
                                          disabled={!editMode && isEditing}
                                          value={stateItem.risks_assumptions}/>
                            </div>
                            <div className=" form-group mb-0">
                                <label className=" form-label">Sources / Links </label>
                                <div className="d-flex flex-column scrollable">
                                    {stateItem?.related_links?.map(link => {
                                        return <span
                                            className="border list-group-item mb-1 bg-white d-flex align-items-center justify-content-between">
                                            <a href={link} className="">{link}</a>
                                            {(editMode || !isEditing) &&
                                            <i className="fa fa-times-circle  cursor color-red" onClick={() => {
                                                removeLink(link)
                                            }}/>}
                                        </span>
                                    })}
                                </div>
                                <div className="input-group mt-3">
                                    <input type="text" className="form-control" placeholder="New link"
                                           value={link}
                                           onChange={(event) => {
                                               setLink(event.target.value)
                                           }}/>
                                    <div className="input-group-append">
                                        <button className="btn btn-success"
                                                disabled={isEditing && !editMode}
                                                onClick={onAddLink}>
                                            Add link
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className=" form-group mb-0">
                                <label className=" form-label">Comments</label>
                                <div className="d-flex flex-column scrollable">
                                    {stateItem?.comments?.map(comment => {
                                        return <span
                                            key={comment}
                                            className="border list-group-item mb-1 bg-white d-flex align-items-center justify-content-between">
                                            <span className="">{comment}</span>
                                            {(editMode || !isEditing) &&
                                            <i className="fa fa-times-circle  cursor color-red" onClick={() => {
                                                removeComment(comment)
                                            }}/>}
                                        </span>
                                    })}
                                </div>
                                <div className="input-group mt-3">
                                    <input type="text" className="form-control" placeholder="New comment"
                                           value={comment}
                                           onChange={(event) => {
                                               setComment(event.target.value)
                                           }}/>
                                    <div className="input-group-append">
                                        <button className="btn btn-success"
                                                disabled={isEditing && !editMode}
                                                onClick={onAddComment}>
                                            Add comment
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>}

                        {activeTab === FILES && <div className="d-flex align-items-center flex-wrap flex-shrink-0">
                            {files?.map((file, index) => {
                                return <div className="file-item ml-1 mb-1 d-flex flex-column"
                                            title="Double click to preview"
                                            key={file.name}
                                            onDoubleClick={(event) => {
                                                console.log('on double click')
                                            }}>
                                    <div className="d-flex
                                    align-items-end
                                    justify-content-end
                                    p-1
                                    position-absolute
                                    cursor
                                    remove-file"
                                         onClick={(event) => {
                                             removeFile(event, file)
                                         }}
                                         title="Click to remove the file">
                                        <i className="fa fa-times text-danger"/>
                                    </div>
                                    <i className="fa fa-file-pdf font-size-1rem mt-2 text-danger mt-5"/>
                                    <span
                                        title={`Double click to preview ${file.name}`}>{file.name.length > 20 ? file.name.substring(0, 20) : file.name}</span>
                                </div>
                            })}
                            <div className="file-item ml-1 mb-1 cursor" onClick={() => {
                                inputRef.click()
                            }}>
                                <div className="d-flex justify-content-around align-items-center">
                                    <i className="fa fa-plus-square font-size-3rem mt-5 text-success"/>
                                </div>
                                <input type="file"
                                       onChange={addFile}
                                       className="custom-input-file"
                                       ref={input => inputRef = input}/>
                            </div>
                        </div>}

                    </div>
                    <hr className="m-0"/>
                    <div className="d-flex justify-content-between p-3">
                        {isEditing ? <span className="d-flex">
                                {!editMode ? <button className="btn btn-warning" onClick={() => {
                                        setEditMode(true)
                                    }}><i className="fa fa-pen-alt mr-1"/>Edit
                                    </button> :
                                    <button className="btn btn-outline-danger" onClick={() => {
                                        setEditMode(false)
                                    }}><i className="fa fa-times"> Cancel</i>
                                    </button>}
                                </span> : <span/>}
                        <div className="d-flex justify-content-between">
                            <button type="button" className="btn btn-danger mr-2" onClick={clickBackdrop}>Close
                            </button>
                            <button type="button" className="btn btn-primary"
                                    onClick={onSave}>Save changes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </ModalWrapper>
    );
};
export default EditModal;
