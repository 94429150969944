
export const GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS';
export const GET_PROJECTS_ERROR = 'GET_PROJECTS_ERROR';
export const ADD_PROJECT = 'ADD_PROJECT';
export const EDIT_PROJECT = 'EDIT_PROJECT';
export const REMOVE_PROJECT = 'REMOVE_PROJECT';

export const ADD_GOAL = 'ADD_GOAL';
export const EDIT_GOAL = 'EDIT_GOAL';
export const REMOVE_GOAL = 'REMOVE_GOAL';

export const ADD_OUTCOME = 'ADD_OUTCOME';
export const EDIT_OUTCOME = 'EDIT_OUTCOME';
export const REMOVE_OUTCOME = 'REMOVE_OUTCOME';

export const ADD_OUTPUT = 'ADD_OUTPUT';
export const EDIT_OUTPUT = 'EDIT_OUTPUT';
export const REMOVE_OUTPUT = 'REMOVE_OUTPUT';

export const ADD_ACTIVITY = 'ADD_ACTIVITY';
export const EDIT_ACTIVITY = 'EDIT_ACTIVITY';
export const REMOVE_ACTIVITY = 'REMOVE_ACTIVITY';


export const ERROR = 'ERROR';
