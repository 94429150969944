import React, {useState} from 'react';
import Goal from "./Goal";
import Aux from '../../../../utils/Aux';
import {outcomeItem as placeholderItem} from "../../../../utils/Data";
import useSyncDispatch from "../../../../hooks/dispatch";
import {ADD_OUTCOME, EDIT_GOAL} from "../../../../store/action-types/logFrame-actions";
import EditModal from "../EditModal/EditModal";
import ApiEndpoints from "../../../../utils/ApiEndpoints";
import useHttp from "../../../../hooks/http";

const Goals = ({projectId, goals}) => {
    const apiEndpoints = new ApiEndpoints();
    const {sendRequest, isLoading} = useHttp();

    const [item, setItem] = useState(placeholderItem);
    const [showModal, setShowModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [goalId, setGoalId] = useState(null);
    const {sendDispatch} = useSyncDispatch();

    const editGoalFn = (goal) => {
        setItem(goal);
    };

    const addOutcome = (outcome) => {
        const {url, method, body, success, error} =
            apiEndpoints
                .getApiEndpoints()
                .outcomes
                .addOutcome(goalId, outcome);
        sendRequest(url, method, body, success, error, response => {
            if (response) {
                sendDispatch(ADD_OUTCOME, {goalId, projectId, outcome: response});
                setItem(placeholderItem);
            }
        });
    };

    const addOutcomeFn = (goalId) => {
        setShowModal(true);
        setItem(placeholderItem);
        setGoalId(goalId);
    };

    const editGoal = (goal) => {
        sendDispatch(EDIT_GOAL, goal);
        setItem(placeholderItem);
    };

    return (
        <Aux>
            {
                goals && goals?.map((goal, idx) => {
                    return <Goal goal={goal}
                                 title={goal.title}
                                 edit={editGoalFn}
                                 addOutcome={addOutcomeFn}
                                 editGoal={editGoal}
                                 dispatch={sendDispatch}
                                 key={goal.id}/>
                })
            }
            <EditModal title={isEditing ? `Edit outcome` : `Add outcome`}
                       item={item}
                       isEditing={isEditing}
                       add={addOutcome}
                // edit={editGoal}
                       show={showModal}
                       clickBackdrop={() => {
                           setShowModal(false);
                           setItem(placeholderItem)
                       }}/>
        </Aux>
    );
};
export default Goals;
