import React from 'react';
import './Backdrop.css';

const backdrop = (props) => {
    const {show, clicked, showFirst, transparentBackground, headerClickable} = props;
    const cssClasses = ['Backdrop', show ? 'BackdropOpen' : 'BackdropClosed'];
    showFirst && cssClasses.push('z-index-9999999');
    cssClasses.push('overflow-y-auto');
    transparentBackground ? cssClasses.push('transparentBg') : cssClasses.push('defaultBg');
    headerClickable ? cssClasses.push('header-clickable') : cssClasses.push('header-not-clickable');
    return (<div onClick={clicked} className={cssClasses.join(' ')}>{props.children}</div>)
};

export default backdrop;
