import React, {useEffect, useState} from 'react';
import RowDropDown from "../Table/RowDropDown";
import Activities from "../Activities/Activities";
import Aux from "../../../../utils/Aux";
import ApiEndpoints from "../../../../utils/ApiEndpoints";
import useHttp from "../../../../hooks/http";
import usePrevProps from "../../../../hooks/prevProps";

const Output = ({output, goalTitle, outcomeTitle, addActivity}) => {
    const apiEndpoints = new ApiEndpoints();
    const {sendRequest, isLoading} = useHttp();

    const [showActivities, setShowActivities] = useState(false);
    const [status, setStatus] = useState(output.status);

    const prevProps = usePrevProps({output});

    const updateStatus = (newStatus) => {
        const {url, method, body, success, error} =
            apiEndpoints
                .getApiEndpoints()
                .outputs
                .changeStatus(output.id, newStatus);
        sendRequest(url, method, body, success, error, response => {
            if (response) {
                setStatus(response.status);
            }
        });
    };

    useEffect(() => {
        if (prevProps?.output?.activities.length !== output?.activities.length && prevProps) {
            setShowActivities(true);
        }
    }, [output.activities?.length]);

    const getBadgeColor = () => {
        return status === 'not started' ? 'badge-danger' : status === 'partially complete' ? 'badge-warning' : status === 'largely complete' ? 'badge-success' : 'dark-green-bg';
    };

    const addActivityFn = () => {
        addActivity(output.id);
    };

    return (
        <Aux>
            <tr className="border-lightgreen bg-lightgreen">
                <th colSpan="4">
                    <span
                        className="mt-3 d-inline-block mr-4 font-weight-bolder bg-white px-3 border-radius color-lightgreen"> {goalTitle} / {outcomeTitle} / {output.title}</span>
                </th>
                <td colSpan="4" className="text-right">
                    <button className="btn btn-sm btn-light color-green" data-toggle="modal"
                            onClick={addActivityFn}
                            data-target="#editModal">
                        <i className="fa fa-plus-circle mr-1"/>
                        Add Activity
                    </button>
                </td>
            </tr>
            <tr className="border-lightgreen">
                <td className="cursor" onClick={() => {
                    setShowActivities(prevState => {
                        return !prevState
                    })
                }}>
                    <button className="btn btn-sm btn-outline-secondary btn-white border-black"><i
                        className={!showActivities ? 'fa fa-caret-down mr-1' : 'fa fa-caret-up mr-1'}/>
                        Activities
                    </button>
                </td>
                <td>
                    <div className="row">
                        <div className="col">
                            <span className="badge badge-primary mr-1">{output.title}</span>
                        </div>

                        <div className="col text-right">
                            <div className="btn-group">
                                <span className={`badge ${getBadgeColor()} mr-2 cursor dropdown-toggle`}
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false">
                                    {status}
                                </span>
                                <div className="dropdown-menu">
                                      <span className="dropdown-item badge dark-green-bg color-white cursor"
                                            onClick={() => {
                                                updateStatus('fully complete')
                                            }}>
                                        Fully Complete
                                    </span>
                                    <span className="dropdown-item badge badge-success color-white cursor"
                                          onClick={() => {
                                              updateStatus('largely complete')
                                          }}>
                                        Largely Complete
                                    </span>
                                    <span className="dropdown-item badge badge-warning color-white cursor"
                                          onClick={() => {
                                              updateStatus('partially complete')
                                          }}>Partially Complete</span>
                                    <span className="dropdown-item badge badge-danger color-white cursor"
                                          onClick={() => {
                                              updateStatus(
                                                  'not started'
                                              )
                                          }}>Not Started</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <br/>
                    {output.summary}
                </td>
                <td>{output.indicators}</td>
                <td>{output.verification}</td>
                <td>{output.risks_assumptions}</td>
                <td>
                    {output?.related_links?.map(link => {
                        return <Aux key={link}><a href={link}>{link}</a><br/></Aux>
                    })}
                </td>
                <td>
                    {output?.comments?.map(comment => {
                        return <span key={comment}>{comment}<br/></span>
                    })}
                </td>
                <RowDropDown/>
            </tr>
            {showActivities &&
            <Activities activities={output.activities} output={output} goalTitle={goalTitle}
                        outcomeTitle={outcomeTitle}/>}
        </Aux>
    );
};
export default Output;
