import React, {useEffect, useState} from 'react';
import Goals from "../Golas/Goals";
import {goalItem as placeholderItem} from '../../../../utils/Data';
import {useSelector} from "react-redux";
import {ADD_GOAL, ADD_OUTCOME} from "../../../../store/action-types/logFrame-actions";
import useSyncDispatch from "../../../../hooks/dispatch";
import EditModal from "../EditModal/EditModal";
import {HIDE_MODAL, SHOW_MODAL} from "../../../../store/action-types/ui-actions";
import ApiEndpoints from "../../../../utils/ApiEndpoints";
import useHttp from "../../../../hooks/http";
import {withRouter} from "react-router-dom";

const Table = ({match}) => {
    const apiEndpoints = new ApiEndpoints();
    const {sendRequest, isLoading} = useHttp();

    const [projectId, setProjectId] = useState(null);

    useEffect(() => {
        const pId = match.params['id'];
        setProjectId(pId);
    }, [match]);

    const [item, setItem] = useState(placeholderItem);
    const [searchTerm, setSearchTerm] = useState('');
    const {project, showEditModal, isEditing, type} = useSelector(state => {
        return {
            project: state.logframeReducer.projects.find(project => project.id === projectId),
            showEditModal: state.uiReducer.showEditModal,
            isEditing: state.uiReducer.isEditing,
            type: state.uiReducer.type
        }
    });

    useEffect(() => {
        document.addEventListener('keyup', onKeyPress, false);
    }, []);

    const onKeyPress = (event) => {
        if (event.key === "Escape") {
            sendDispatch(HIDE_MODAL);
        }
    };

    const {sendDispatch} = useSyncDispatch();

    const addGoalFn = () => {
        setItem(placeholderItem);
        sendDispatch(SHOW_MODAL, {isEditing: false, type: 'goal'});
    };

    const addGoal = (goal) => {
        const {url, method, body, success, error} =
            apiEndpoints
                .getApiEndpoints()
                .goals
                .addGoal(projectId, goal);
        sendRequest(url, method, body, success, error, response => {
            if (response) {
                sendDispatch(ADD_GOAL, {goal: response, projectId});
                setItem(placeholderItem);
            }
        });
    };

    const getProjects = () => {
        const {url, method, body, success, error} =
            apiEndpoints
                .getApiEndpoints()
                .projects
                .getProjects();
        sendRequest(url, method, body, success, error);
    };


    return (
        <div className="page">
            <div className="page-main mb-5">
                <div className="my-3 my-md-5">
                    <div className="px-5">
                        <div className="row">
                            <div className="col">
                                <div className="card">
                                    <div className="card-header border-grey">
                                        <h3 className="card-title color-lightgreen font-weight-bold font-size-25">
                                            {project && project.name}
                                        </h3>
                                        <div className="card-options">
                                            <div className="m-auto pr-2">
                                                <button className="btn btn-sm btn-light color-purple"
                                                        onClick={addGoalFn}>
                                                    <i className="fa fa-plus-circle mr-1"/>
                                                    Add Goal
                                                </button>
                                            </div>
                                            <form action="">
                                                <div className="input-group">
                                                    <input type="text"
                                                           onChange={(e) => {
                                                               setSearchTerm(e.target.value)
                                                           }}
                                                           className="form-control form-control-sm"
                                                           value={searchTerm}
                                                           placeholder="Search something..."
                                                           name="s"/>
                                                    <span className="input-group-btn ml-2">
                                                <button className="btn btn-sm btn-default" type="submit">
                                                    <span className="fe fe-search"/>
                                                  </button>
                                            </span>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <table className="table card-table table-vcenter text-left">
                                        <tbody>
                                        <tr>
                                            <th/>
                                            <th>Summary</th>
                                            <th>Indicators</th>
                                            <th className="d-none d-sm-table-cell">Means of Verification</th>
                                            <th className="d-none d-md-table-cell">Risks / Assumptions</th>
                                            <th className="d-none d-md-table-cell">Sources / Links</th>
                                            <th className="d-none d-md-table-cell w-15">Comments</th>
                                            <th/>
                                        </tr>
                                        {project &&
                                        <Goals addGoal={addGoal} goals={project.goals} projectId={projectId}/>}
                                        </tbody>

                                    </table>
                                </div>
                            </div>
                        </div>

                        <EditModal title={isEditing ? `Edit ${type}` : `Add ${type}`}
                                   item={item}
                                   isEditing={isEditing}
                                   add={addGoal}
                            // edit={editGoal}
                                   show={showEditModal}
                                   clickBackdrop={() => {
                                       sendDispatch(HIDE_MODAL);
                                       setItem(placeholderItem)
                                   }}/>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default withRouter(Table);
