import React from 'react';
import RowDropDown from "../Table/RowDropDown";
import Aux from "../../../../utils/Aux";

const Activity = ({activity, goalTitle, outcomeTitle, outputTitle}) => {
    return (
        <Aux>
            <tr className="border-green bg-green-dark">
                <th colSpan="4">
                    <span
                        className="mt-3 d-inline-block mr-4 font-weight-bolder bg-white px-3 border-radius color-green">
                    Goal {goalTitle} / {outcomeTitle} / {outcomeTitle} / {activity.title}
                    </span>
                </th>
                {/*<td colSpan="2" className="text-right">*/}
                {/*    <button className="btn btn-sm btn-outline-success"><i className="fa fa-plus-circle mr-1"/>*/}
                {/*        Add Activity*/}
                {/*    </button>*/}
                {/*</td>*/}
                <th colSpan='4'/>
            </tr>
            <tr className="border-green">
                <th/>
                <td>
                    <span className="badge badge-success mr-1">{activity.title}</span>
                    <br/>
                    {activity.summary}
                </td>
                <td>{activity.indicators}</td>
                <td>{activity.verification}</td>
                <td>{activity.risks_assumptions}</td>
                <td>
                    {activity?.related_links?.map(link => {
                        return <Aux key={link}><a href={link}>{link}</a><br/></Aux>
                    })}
                </td>
                <td>
                    {activity?.comments?.map(comment => {
                        return <span key={comment}>{comment}<br/></span>
                    })}
                </td>
                <RowDropDown/>
            </tr>
        </Aux>
    );
};
export default Activity;
